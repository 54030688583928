import React, { useState, useEffect } from "react";
import { Box, ImageList, ImageListItem } from "@mui/material";
import { bookImagesAndMetadata } from "../BookLibrary";
import { CustomDiv } from "../../components/CustomDiv";

export const MainPageFeaturedBooks = ({ navigate }) => {
  const [featuredBooks, setFeaturedBooks] = useState([]);

  useEffect(() => {
    setFeaturedBooks(() => [...bookImagesAndMetadata]);
  }, []);

  const onClick = (uid) => {
    navigate(`/reader/${uid}`, { state: { uid: uid } });
  };

  return (
    featuredBooks && (
      <Box style={{ padding: "10px" }} sx={{ overflowY: "scroll" }}>
        <ImageList sx={{ maxHeight: 450 }} variant="woven" cols={2} gap={8}>
          {featuredBooks.map((item, index) => (
            <ImageListItem
              key={item.img}
              onClick={() => onClick(item.metadata.uid)}
            >
              <img
                src={`${item.img}?w=161&fit=crop&auto=format`}
                srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    )
  );
};
