import { Grid } from "@mui/material";
import React from "react";
import { BookCards } from "../../components/BookCards";
import pageContent from "../../components/PageInformation";
import "./MainPageFeatured.css";

import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { MAIN_PAGE_FEATURED_AUTHOR } from "../../utilities/constantsLocale";
import { useFetchData } from "../../utilities/useFetchData";

export const MainPageFeaturedAuthor = ({ currentLanguage }) => {
  // const [authorData, setAuthorData] = useState({});

  const { t } = useTranslation();

  const authorData = useFetchData("authors", "1234", currentLanguage);
  const profileImage = useFetchData(
    "artwork",
    get(authorData, "metadata.profileImage")
  );

  return (
    authorData &&
    authorData.metadata && (
      <Grid container direction="row">
        <Grid item xs={12} sm={12} md={12} xl={12} textAlign={"center"}>
          <h1>{t(`${MAIN_PAGE_FEATURED_AUTHOR}header`)}</h1>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          xl={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={profileImage}
            alt={authorData.metadata["author"]}
            style={{
              width: "100%",
              borderRadius: "5px",
            }}
          />
        </Grid>
        <Grid item xs={0} sm={1} md={1} xl={1}></Grid>

        <Grid item xs={12} sm={7} md={7} xl={7} textAlign={"justify"}>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            {pageContent(authorData.metadata["author"])}
          </Grid>

          {authorData.content["stats"].map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6} textAlign={"justify"}>
          <div
            className="textBiography"
            style={{
              height: "500px",
              overflow: "auto",
            }}
          >
            {" "}
            {authorData.content["biography"].map((item, index) => (
              <>
                {pageContent(item["title"])}
                <p key={index}>{item["text"]}</p>
              </>
            ))}
          </div>
        </Grid>
        <Grid item xs={0} sm={1} md={1} xl={1}></Grid>
        {authorData.catalog && (
          <Grid item xs={12} sm={5} md={5} xl={5}>
            {pageContent(t(`${MAIN_PAGE_FEATURED_AUTHOR}works`))}

            <BookCards bookCatalog={authorData.catalog} />
          </Grid>
        )}
      </Grid>
    )
  );
};
