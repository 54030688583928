import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Img, PRIMARY_COLOR } from "../utilities/Constants";
import { COMPONENTS_SPEED_DIAL_UTILITIES } from "../utilities/constantsLocale";
import PrimaryShareDialog, { FallbackShareDialog } from "./ShareDialog";

const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
  },
});

const handleOnClickShare = (currentData) => {
  const speedDialog = false;

  const chapterTitle = currentData[0].title;
  const chapterContent = currentData[0].chapter;
  const shareDialogFallback = PrimaryShareDialog(chapterTitle, chapterContent);

  return { speedDialog, shareDialogFallback };
};

const handleOnClickCopyToClipboard = (currentData) => {
  navigator.clipboard.writeText(currentData[0].chapter);

  const speedDialog = false;
  return { speedDialog };
};

const handleOnClickPrintPage = (currentData) => {
  window.print();
  const speedDialog = false;

  return { speedDialog };
};

const actions = [
  {
    icon: <FileCopyIcon />,
    name: "copy",
    actionClick: handleOnClickCopyToClipboard,
  },
  {
    icon: <PrintIcon />,
    name: "print",
    actionClick: handleOnClickPrintPage,
  },
  { icon: <ShareIcon />, name: "share", actionClick: handleOnClickShare },
];

const ImageStyledComponent = ({
  imageSource,
  imageAlternativeText = "altText",
}) => {
  return (
    <Img
      alt={imageAlternativeText}
      src={imageSource}
      sx={{ maxWidth: 150, m: "20px" }}
    />
  );
};

const SpeedDialUtilities = ({ bookCover, bookTitle, currentData }) => {
  const [open, setOpen] = useState(false);
  const [openShareDialogStatus, setShareDialogStatus] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { t, i18n } = useTranslation();

  const handleCloseDialog = () => {
    setShareDialogStatus(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <SpeedDial
        style={{ position: "fixed" }}
        ariaLabel="speedDial"
        FabProps={{
          color: "primary",
        }}
        sx={{
          position: "absolute",
          bottom: 30,
          right: 20,
        }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            FabProps={{
              color: "primary",
            }}
            key={action.name}
            icon={action.icon}
            tooltipTitle={t(`${COMPONENTS_SPEED_DIAL_UTILITIES}${action.name}`)}
            tooltipOpen
            onClick={() => {
              const status = action.actionClick(currentData);
              setOpen(status.speedDialog);
              setShareDialogStatus(status.shareDialogFallback);
            }}
          />
        ))}
      </SpeedDial>
      {openShareDialogStatus && (
        <FallbackShareDialog
          dialogMessage={bookTitle}
          imagePreview={
            <ImageStyledComponent
              imageSource={bookCover}
              imageAlternativeText="imageAlt"
            />
          }
          open={openShareDialogStatus}
          onClose={handleCloseDialog}
        />
      )}
    </ThemeProvider>
  );
};

export default SpeedDialUtilities;
