import FavoriteIcon from "@mui/icons-material/Favorite";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {
  Box,
  Breadcrumbs,
  CssBaseline,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import * as React from "react";

function SocialMediaLinks() {
  return (
    <Typography variant="body1">
      <Link
        color="inherit"
        href="https://www.instagram.com/almasmyrzatay/?hl=en"
      >
        {<InstagramIcon sx={{ fontSize: 40 }} />}
      </Link>
      <Link color="inherit" href="https://www.linkedin.com/in/almasmyrzatay">
        {<LinkedInIcon sx={{ fontSize: 40 }} />}
      </Link>
    </Typography>
  );
}

function WebsiteAuthor() {
  return (
    <Typography variant="body1">
      With {<FavoriteIcon sx={{ color: pink[500], fontSize: "small" }} />} by
      Almas{" "}
    </Typography>
  );
}

function WebsiteDocs() {
  const websiteDocsLink = [
    <Link key="contact" href="/wip" color="inherit">
      {"Copyright © "}

      {new Date().getFullYear()}
    </Link>,
    <Link key="wip-1" href="/wip" color="inherit">
      {"Privacy"}
    </Link>,

    <Link key="wip-2" href="/contact" color="inherit">
      {"About Us"}
    </Link>,
    <Link key="wip-3" href="/wip" color="inherit">
      {"Cookies"}
    </Link>,
  ];

  return (
    <Breadcrumbs separator="|" aria-label="breadcrumb">
      {/* <Typography variant="body2" color="text.secondary"> */}
      {websiteDocsLink}
      {/* </Typography> */}
    </Breadcrumbs>
  );
}

export const MainFooter = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        //minHeight: "30vh",
        flexGrow: 1,
      }}
    >
      <CssBaseline />
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={8} md={8}>
            <WebsiteAuthor />
            <Grid item xs={12} md={12}>
              <WebsiteDocs />
            </Grid>
          </Grid>
          <Grid item xs={4} md={4} textAlign="end" alignItems="center">
            <SocialMediaLinks />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default MainFooter;
