import {
  LOCALE_DEFAULT_OPTION,
  LOCALE_ENGLISH_US,
  LOCALE_LATIN_QAZAQ,
  LOCALE_RUSSIAN,
} from "../utilities/Constants";

const CyrillicLatinConverterFunction = (
  contentTextAlphabet,
  toChangeContentText
) => {
  let updatedContentText = "";

  toChangeContentText.split("").forEach((character) => {
    if (character in contentTextAlphabet) {
      updatedContentText += contentTextAlphabet[character];
    } else {
      updatedContentText += character;
    }
  });
  return updatedContentText;
};

const LetterChanger = (contentTextLocale, toChangeContentText) => {
  if (contentTextLocale === LOCALE_LATIN_QAZAQ) {
    return CyrillicLatinConverterFunction(
      alphabetCyrillic,
      toChangeContentText
    );
  } else if (contentTextLocale === LOCALE_DEFAULT_OPTION) {
    return CyrillicLatinConverterFunction(alphabetLatin, toChangeContentText);
  } else {
    return toChangeContentText;
  }
};

const alphabetCyrillic = {
  А: "A",
  а: "а",
  Ә: "Á",
  ә: "á",
  Б: "B",
  б: "b",
  В: "V",
  в: "v",
  Г: "G",
  г: "g",
  Ғ: "Ǵ",
  ғ: "ǵ",
  Д: "d",
  д: "d",
  Е: "E",
  е: "e",
  Ж: "J",
  ж: "j",
  З: "Z",
  з: "z",
  И: "I",
  и: "ı",
  Й: "I",
  й: "ı",
  К: "K",
  к: "k",
  Қ: "Q",
  қ: "q",
  Л: "L",
  л: "l",
  М: "M",
  м: "m",
  Н: "N",
  н: "n",
  Ң: "Ń",
  ң: "ń",
  О: "O",
  о: "o",
  Ө: "Ó",
  ө: "ó",
  П: "P",
  п: "p",
  Р: "R",
  р: "r",
  С: "S",
  с: "s",
  Т: "T",
  т: "t",
  У: "Ý",
  у: "ý",
  Ұ: "U",
  ұ: "u",
  Ү: "Ú",
  ү: "ú",
  Ф: "F",
  ф: "f",
  Х: "H",
  х: "h",
  Һ: "h",
  Ч: "Ch",
  ч: "ch",
  Ш: "Sh",
  ш: "sh",
  Ы: "Y",
  ы: "y",
  І: "I",
  і: "i",
};

const alphabetLatin = {
  A: "А",
  а: "а",
  Á: "Ә",
  á: "ә",
  B: "Б",
  b: "б",
  V: "В",
  v: "в",
  G: "Г",
  g: "г",
  Ǵ: "Ғ",
  ǵ: "ғ",
  d: "Д", // eslint-disable-next-line
  d: "д",
  E: "Е",
  e: "е",
  J: "Ж",
  j: "ж",
  Z: "З",
  z: "з",
  I: "И",
  ı: "и", // eslint-disable-next-line
  I: "Й", // eslint-disable-next-line
  ı: "й",
  K: "К",
  k: "к",
  Q: "Қ",
  q: "қ",
  L: "Л",
  l: "л",
  M: "М",
  m: "м",
  N: "Н",
  n: "н",
  Ń: "Ң",
  ń: "ң",
  O: "О",
  o: "о",
  Ó: "Ө",
  ó: "ө",
  P: "П",
  p: "п",
  R: "Р",
  r: "р",
  S: "С",
  s: "с",
  T: "Т",
  t: "т",
  Ý: "У",
  ý: "у",
  U: "Ұ",
  u: "ұ",
  Ú: "Ү",
  ú: "ү",
  F: "Ф",
  f: "ф",
  H: "Х",
  h: "х", // eslint-disable-next-line
  h: "Һ",
  Ch: "Ч",
  ch: "ч",
  Sh: "Ш",
  sh: "ш",
  Y: "Ы",
  y: "ы", // eslint-disable-next-line
  I: "І",
  i: "і",
};

export default LetterChanger;
export { alphabetCyrillic, alphabetLatin };
