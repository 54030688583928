import ImportContacts, {
  default as MainIcon,
} from "@mui/icons-material/ImportContacts";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Button } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LetterChanger from "../converter/LetterChanger";
import BookCardDialog from "../home/BookCardDialog";
import { PRIMARY_COLOR } from "../utilities/Constants";
import { READER_PAGE_BREADCRUMBS } from "../utilities/constantsLocale";

const ReaderBreadcrumbs = ({
  title,
  uid,
  metadata,
  selectedLocaleToDisplay,
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const onClick = (value) => {
    if (value === "/book") {
      navigate("/book");
    } else {
      setOpen(true);
    }
  };

  return (
    <div role="presentation">
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={
          <NavigateNextIcon fontSize="small" style={{ color: PRIMARY_COLOR }} />
        }
      >
        <Button
          variant="contained"
          onClick={() => onClick("/book")}
          startIcon={<ImportContacts sx={{ mb: "5px" }} />}
          style={{ backgroundColor: PRIMARY_COLOR, margin: "2px" }}
        >
          {t(`${READER_PAGE_BREADCRUMBS}libraryButton`)}
        </Button>

        <Button
          variant="contained"
          onClick={() => onClick("reader/")}
          startIcon={<MainIcon sx={{ mb: "5px" }} />}
          style={{ backgroundColor: PRIMARY_COLOR, margin: "2px" }}
        >
          {LetterChanger(selectedLocaleToDisplay, metadata.title)}
        </Button>
      </Breadcrumbs>
      {open && (
        <BookCardDialog
          open={open}
          onClose={handleClose}
          bookMetadata={metadata}
          bookCover={require("../data/cover_photos/" + metadata.uid + ".png")}
          selectedLocaleToDisplay={selectedLocaleToDisplay}
        />
      )}
    </div>
  );
};

export default ReaderBreadcrumbs;
