import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import "./i18n/config";
import "./i18n";
// import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";
// import CircularProgress from "@mui/material/CircularProgress";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense
      // fallback={
      //   <CircularProgress
      //     sx={{
      //       height: "100vh",
      //       textAlign: "center",
      //       justifyContent: "center",
      //       alignItems: "center",
      //       display: "flex",
      //     }}
      //   />
      // }
      >
        {" "}
        <App />
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
