import { Dialog, Grid, Divider, Stack } from "@mui/material";
import {
  FacebookShareButton,
  MailruShareButton,
  TelegramShareButton,
  VKShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import {
  FacebookIcon,
  MailruIcon,
  TelegramIcon,
  VKIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import MainImage from "../background.jpg";
import { pageTitle } from "./PageInformation";
import {
  PRIMARY_COLOR,
  WEBSITE_NAME,
  WEBSITE_URL,
} from "../utilities/Constants";

const socialMediaNames = [
  "Facebook",
  "Mail.ru",
  "Telegram",
  "VKontakte",
  "Email",
  "WhatsApp",
];

const socialMediaItems = [
  <FacebookShareButton url={WEBSITE_URL} quote={WEBSITE_NAME}>
    <FacebookIcon borderRadius={5} size={32} />
  </FacebookShareButton>,
  <MailruShareButton url={WEBSITE_URL} quote={WEBSITE_NAME}>
    <MailruIcon borderRadius={5} size={32} />
  </MailruShareButton>,
  <TelegramShareButton url={WEBSITE_URL} quote={WEBSITE_NAME}>
    <TelegramIcon borderRadius={5} size={32} />
  </TelegramShareButton>,
  <VKShareButton url={WEBSITE_URL} quote={WEBSITE_NAME}>
    <VKIcon borderRadius={5} size={32} />
  </VKShareButton>,

  <EmailShareButton url={WEBSITE_URL} quote={WEBSITE_NAME}>
    <EmailIcon borderRadius={5} size={32} />
  </EmailShareButton>,

  <WhatsappShareButton url={WEBSITE_URL} quote={WEBSITE_NAME}>
    <WhatsappIcon borderRadius={5} size={32} />
  </WhatsappShareButton>,
];

const SocialMediaGridContainer = () => {
  return (
    <Grid container sx={{ p: 2 }} spacing={{ xs: 2, md: 3 }}>
      {socialMediaItems.map((socialMedia, index) => (
        <Grid item xs={4} sm={4} md={4} key={index}>
          <Stack>
            {socialMedia}
            {socialMediaNames[index]}
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

const FallbackShareDialog = ({
  onClose,
  open,
  imagePreview,
  dialogMessage,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Grid
        container
        direction="row"
        style={{ padding: "10px", backgroundImage: `url(${MainImage})` }}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        {pageTitle("Бөлісу")}
        {imagePreview}
        <Grid item xs={12} s={12} md={12} lg={12}>
          {dialogMessage}
        </Grid>

        <Grid item xs={12} s={12} md={12} lg={12}>
          <Divider
            variant="middle"
            sx={{
              borderBottomWidth: 1,
              backgroundColor: PRIMARY_COLOR,
              boxShadow: "0 0 10px",
            }}
          />
        </Grid>
        <SocialMediaGridContainer />
      </Grid>
    </Dialog>
  );
};

const PrimaryShareDialog = ({ chapterTitle, chapterContent }) => {
  let shareDialogFallback = false;

  if (navigator.canShare && navigator.share) {
    navigator
      .share({
        title: `Kitap - ${chapterTitle}`,
        text: chapterContent,
        url: WEBSITE_URL,
      })
      .then(() => {
        console.log("Successfully shared");
      })
      .catch((error) => {
        console.error("Something went wrong", error);
      });
  } else {
    console.log("Browser doesn't support ");
    shareDialogFallback = true;
  }

  return shareDialogFallback;
};

export default PrimaryShareDialog;
export { FallbackShareDialog, SocialMediaGridContainer };
