import AddCommentIcon from "@mui/icons-material/AddComment";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import HomeIcon from "@mui/icons-material/Home";
import ImportContacts from "@mui/icons-material/ImportContacts";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import { PRIMARY_COLOR } from "../utilities/Constants";
//import SendIcon from "@mui/icons-material/Send";
import { SwapHoriz } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MAIN_PAGE_MENU_BAR } from "../utilities/constantsLocale";

import LanguageIcon from "@mui/icons-material/Language";
import QzLocale from "../data/locale/icons/QZ.png";
import UsLocale from "../data/locale/icons/US.png";

const drawerWidth = 240;
const navItems = [
  {
    title: `${MAIN_PAGE_MENU_BAR}mainPage`,
    link: "/",
    icon: <HomeIcon />,
  },
  {
    title: `${MAIN_PAGE_MENU_BAR}library`,
    link: "/book",
    icon: <ImportContacts />,
  },
  {
    title: `${MAIN_PAGE_MENU_BAR}converter`,
    link: "/convert",
    icon: <SwapHoriz />,
  },
  {
    title: `${MAIN_PAGE_MENU_BAR}contactUs`,
    link: "/contact",
    icon: <ContactSupportIcon />,
  },
  {
    title: `${MAIN_PAGE_MENU_BAR}supportUs`,
    link: "/wip",
    icon: <AddCommentIcon />,
  },
];

function MainMenu({ window }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onClick = (value) => {
    navigate(value);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      {/* <Grid item> */}
      <Typography variant="h6" sx={{ m: 2 }}>
        <span style={{ color: "#001C58" }}>Qaz</span>
        <span style={{ color: "#FFD700" }}>Bilim</span>
      </Typography>

      <List>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            <ListItemButton onClick={() => onClick(item.link)}>
              <ListItemText primary={t(item.title)} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {/* </Grid> */}
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      {/* md OR lg */}
      <AppBar
        component="nav"
        sx={{
          backgroundColor: PRIMARY_COLOR,
          backgroundImage: "linear-gradient(to left, #3a5a40, #5c8265)",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <span style={{ color: "#001C58" }}>Qaz</span>
            <span style={{ color: "#FFD700" }}>Bilim</span>
          </Typography>
          <Divider />
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item, index) => (
              <Button
                key={item.title}
                sx={{ color: "#fff" }}
                // startIcon={item.icon}
                onClick={() => onClick(item.link)}
              >
                {t(item.title)}
              </Button>
            ))}
          </Box>
          <React.Fragment>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Tooltip title={t(`${MAIN_PAGE_MENU_BAR}languages`)}>
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "language-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar
                    sx={{ width: 32, height: 32, backgroundColor: "white" }}
                  >
                    {i18n.language === "cyKk" ? (
                      <Avatar src={QzLocale} />
                    ) : (
                      <Avatar src={UsLocale} />
                    )}
                  </Avatar>
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="language-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={() => changeLanguage("cyKk")}>
                <Avatar src={QzLocale} /> Qazaq
              </MenuItem>
              <MenuItem onClick={() => changeLanguage("enUs")}>
                <Avatar src={UsLocale} /> English
              </MenuItem>
            </Menu>
          </React.Fragment>
        </Toolbar>
      </AppBar>
      {/* END OF md OR lg */}

      {/* BEG OF sm OR xs */}

      <Grid component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              justifyContent: "space-between",
              backgroundColor: PRIMARY_COLOR,
              backgroundImage: "linear-gradient(to right, #3a5a40, #4b6e52)",
              color: "white",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Grid>

      <Box component="main">
        <Toolbar />
      </Box>

      {/* END OF sm OR xs */}
    </Box>
  );
}

export default MainMenu;
