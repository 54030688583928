import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Contact from "./contact/Contact";
import Convert from "./converter/Converter";
import MainMenu from "./home/MainMenu";
import { MainPage } from "./home/MainPage";
import Reader from "./reader/Reader";

import { MainFooter } from "./home/MainFooter";

import BookLibrary from "./home/BookLibrary";
import WorkInProgress from "./wip/WorkInProgress";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./App.css";

const theme = createTheme({
  typography: {
    fontFamily: [
      // "Old Standard TT",
      "Roboto",
      "monospace",
      "Helvetica Neue",
    ].join(","),
    // allVariants: {
    //   color: PRIMARY_COLOR,
    // },
  },
  palette: {
    // background: {
    //   default: "#dfdbce",
    // },
    // mode: "dark",
  },
});

const NoMatchPage = () => {
  return <h3>404 - Not found</h3>;
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="app">
        <div className="main">
          <header className="App-header"></header>
          <MainMenu></MainMenu>

          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/book" element={<BookLibrary />} />
            <Route path="/reader/" element={<Navigate to="/book" />} />
            <Route path="/reader/:uid" element={<Reader />} />
            <Route path="/convert" element={<Convert />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/wip" element={<WorkInProgress />} />
            <Route component={NoMatchPage} />
          </Routes>
        </div>

        <MainFooter />
      </div>
    </ThemeProvider>
  );
};

export default App;
