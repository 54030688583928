import React, { useEffect, useState } from "react";
import { Alert, Fade } from "@mui/material";
import { PRIMARY_COLOR } from "../utilities/Constants";
import { COMPONENTS, CONVERT_PAGE } from "../utilities/constantsLocale";

const DetermineType = (inputStatusType) => {
  let statusType = "success";
  if (inputStatusType.includes("error")) {
    statusType = "error";
  } else if (inputStatusType.includes("info")) {
    statusType = "info";
  }

  return statusType;
};

const AlertMessage = ({ alertName, alertDelay, t }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (alertDelay) {
      const timer = setTimeout(() => {
        setVisible(false);
      }, alertDelay);
      return () => clearTimeout(timer);
    }
  }, [alertDelay]);

  const alertType = DetermineType(alertName);
  const alertMessage = t(`${COMPONENTS}alertMessage.${alertName}`);

  const alertSuccess = alertType === "success" && {
    backgroundColor: PRIMARY_COLOR,
  };

  return (
    visible && (
      <Fade in={true} style={{ transitionDelay: true ? "700ms" : "0ms" }}>
        <Alert
          variant="filled"
          severity={alertType}
          sx={{
            m: 2,
            color: "white",
            ...alertSuccess,
          }}
        >
          {alertMessage}
        </Alert>
      </Fade>
    )
  );
};

export default AlertMessage;
