import { Box, Grid, Typography } from "@mui/material";

import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Img } from "../../utilities/Constants";
import { MAIN_PAGE_FEATURED_PAINTER } from "../../utilities/constantsLocale";
import { useFetchData } from "../../utilities/useFetchData";

const artistsWork = [
  require("../../data/content/artwork/1571.jpeg"),
  require("../../data/content/artwork/1572.jpeg"),
  require("../../data/content/artwork/1573.jpeg"),
  require("../../data/content/artwork/1574.jpeg"),
];

const ThumbnailPlugin = (mainRef) => {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove("active");
      });
    }
    function addActive(idx) {
      slider.slides[idx].classList.add("active");
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener("click", () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx);
        });
      });
    }

    slider.on("created", () => {
      if (!mainRef.current) return;
      addActive(slider.track.details.rel);
      addClickEvents();
      mainRef.current.on("animationStarted", (main) => {
        removeActive();
        const next = main.animator.targetIdx || 0;
        addActive(main.track.absToRel(next));
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next));
      });
    });
  };
};

export const FeaturedArtistsBio = ({ bioData }) => {
  const profileImage = useFetchData("artwork", bioData.profileImage);

  return (
    <>
      <Grid container direction="row">
        <Grid item xs={12} sm={12} md={12} xl={12} textAlign={"center"}>
          <h2>{bioData.artistName}</h2>
          <h3>{bioData.dateOfBirth}</h3>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          xl={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={profileImage}
            style={{
              width: "100%",
              borderRadius: "5px",
            }}
          />
        </Grid>
        <Grid item xs={0} sm={1} md={1} xl={1}></Grid>
        <Grid item xs={12} sm={7} md={7} xl={7} textAlign={"justify"} my={2}>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            {bioData.summary}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const MainPageFeaturedArtists = ({ currentLanguage }) => {
  const { t } = useTranslation();

  const artistData = useFetchData("artists", "3322", currentLanguage);

  const [thumbnailSliderRef, instanceRef] = useKeenSlider({
    initial: 0,
  });

  const [thumbnailRef] = useKeenSlider(
    {
      initial: 0,
      slides: {
        perView: 4,
        spacing: 1,
      },
    },
    [ThumbnailPlugin(instanceRef)]
  );

  return (
    artistData && (
      <>
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <Box
            sx={{
              borderRadius: "5px",
              p: 2,
              m: 2,
              backgroundColor: "#dfdbce",
            }}
          >
            <h1>{t(`${MAIN_PAGE_FEATURED_PAINTER}featured-painter-header`)}</h1>
            <div ref={thumbnailSliderRef} className="keen-slider">
              {/* https://365info.kz/2015/05/10-izvestnyx-kartin-abilxana-kasteeva */}
              {artistsWork.map((item, index) => (
                <>
                  <div
                    key={index}
                    // variant="outlined"
                    className={`keen-slider__slide number-slide${index}`}
                  >
                    <Box position="relative" display="inline-block">
                      <Img
                        style={{ borderRadius: "5px", height: "300px" }}
                        src={item}
                      />
                      <Typography
                        variant="body1"
                        component="div"
                        style={{
                          position: "absolute",
                          top: "90%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          // backgroundColor: "rgba(255, 255, 255, 0.5)", // Adjust the opacity as needed
                          padding: "10px",
                          // borderRadius: "5px",
                        }}
                      >
                        {artistData.content[index].title}
                      </Typography>
                    </Box>
                  </div>
                </>
              ))}
            </div>

            <div ref={thumbnailRef} className="keen-slider thumbnail">
              {artistsWork.map((item, index) => (
                <>
                  <div
                    key={index}
                    // variant="outlined"
                    className={`keen-slider__slide number-slide${index}`}
                  >
                    <img
                      style={{
                        // borderRadius: "5px",
                        objectFit: "fill",
                        display: "block",
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                      src={item}
                    />
                  </div>
                </>
              ))}
            </div>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6} xl={6}>
          <Box
            sx={{
              p: 2,
              m: 2,
            }}
          >
            <FeaturedArtistsBio bioData={artistData.metadata} />
          </Box>
        </Grid>
      </>
    )
  );
};
