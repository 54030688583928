import { Grid, Typography, Button } from "@mui/material";
import { PRIMARY_COLOR } from "../utilities/Constants";
import HomeIcon from "@mui/icons-material/Home";

const BackButton = (props) => {
  const textToDisplay = props?.linkText ? props.linkText : "Бас бет";

  return (
    <Button
      variant="contained"
      style={{ backgroundColor: PRIMARY_COLOR }}
      startIcon={
        props?.icon ? props.icon : <HomeIcon style={{ marginBottom: "4px" }} />
      }
      onClick={() => {
        const linkToNavigate = props?.link ? props.link : "/";

        props.navigate(linkToNavigate);
      }}
    >
      {textToDisplay}
    </Button>
  );
};

const pageTitle = (title) => {
  return (
    <Grid
      item
      xs={12}
      key="title"
      justifyContent="center"
      style={{ textAlign: "center" }}
    >
      <Typography fontWeight="bold" my={2} variant="subtitle1" fontSize="30px">
        {title}
      </Typography>
    </Grid>
  );
};

const pageContent = (content, contentKey = "content") => {
  return (
    <Grid
      item
      xs={12}
      key={contentKey}
      sx={{ p: "10px" }}
      justifyContent="center"
      style={{ textAlign: "center" }}
    >
      <Typography
        fontWeight="medium"
        gutterBottom
        variant="subtitle2"
        fontSize="20px"
        whiteSpace="break-spaces"
      >
        {content}
      </Typography>
    </Grid>
  );
};

export default pageContent;
export { pageTitle, BackButton };
