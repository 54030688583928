import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MainImage from "../background.jpg";
import { BackButton } from "../components/PageInformation";
import SpeedDialUtilities from "../components/SpeedDialUtilities";
import {
  LOCALE_DEFAULT_OPTION,
  MEDIUM_WINDOW_BREAK_POINT,
  PAGINATION_PER_PAGE,
} from "../utilities/Constants";
import { READER_PAGE } from "../utilities/constantsLocale";
import { useFetchData } from "../utilities/useFetchData";
import usePagination from "../utilities/usePagination";
import { useWindowDimensions } from "../utilities/useWindowDimensions";
import WorkInProgress from "../wip/WorkInProgress";
import ReaderBreadcrumbs from "./ReaderBreadcrumbs";
import ReaderLanguageChanger from "./ReaderLanguageChanger";
import ReaderPagination from "./ReaderPagination";

const Reader = () => {
  const [selectedLocaleToDisplay, setSelectedLocaleToDisplay] = useState(
    LOCALE_DEFAULT_OPTION
  );

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { t, i18n } = useTranslation();

  // console.log("params: ", params);
  // console.log("location.state.uid ", location?.state?.uid);

  const currentUid = location?.state?.uid ? location?.state?.uid : params.uid;

  console.log("currentUid ", currentUid);

  const data = useFetchData("books", currentUid, selectedLocaleToDisplay);
  const paginatedData = usePagination(data?.content, PAGINATION_PER_PAGE);

  const windowSize = useWindowDimensions();

  return (
    <Box style={{ backgroundImage: `url(${MainImage})` }}>
      <Grid container style={{ padding: "15px" }} spacing={2}>
        <Grid
          item
          xs={windowSize.width > MEDIUM_WINDOW_BREAK_POINT ? 8 : 12}
          key="breadcrumb"
        >
          <Box>
            {data?.metadata && (
              <ReaderBreadcrumbs
                title={data.metadata.title}
                uid={data.metadata.uid}
                metadata={data.metadata}
                selectedLocaleToDisplay={selectedLocaleToDisplay}
              />
            )}
          </Box>
        </Grid>
        {windowSize.width > MEDIUM_WINDOW_BREAK_POINT && (
          <Grid item xs={4} key="back-button" textAlign="end">
            <BackButton
              navigate={navigate}
              linkText={t(`${READER_PAGE}mainPageButton`)}
            />
          </Grid>
        )}
        {
          <ReaderLanguageChanger
            handleLanguageSelection={setSelectedLocaleToDisplay}
          />
        }
        <Grid container justifyContent="center" style={{ textAlign: "center" }}>
          {data?.content.length > 0 ? (
            <ReaderPagination
              paginatedData={paginatedData}
              count={Math.ceil(data.content.length / PAGINATION_PER_PAGE)}
              selectedLocaleToDisplay={selectedLocaleToDisplay}
            />
          ) : (
            <WorkInProgress link={"/book"} />
          )}
        </Grid>
        {data?.metadata && (
          <SpeedDialUtilities
            bookCover={require("../data/cover_photos/" +
              data.metadata.uid +
              ".png")}
            bookTitle={data.metadata.title}
            currentData={paginatedData.currentData()}
          />
        )}
      </Grid>
    </Box>
  );
};

export default Reader;
