import { Grid, Box, Divider } from "@mui/material";
import ExploreIcon from "@mui/icons-material/Explore";
import { SwapHoriz } from "@mui/icons-material";
import ImportContacts from "@mui/icons-material/ImportContacts";
import { useTranslation, Trans } from "react-i18next";
import {
  PRIMARY_COLOR,
  MEDIUM_WINDOW_BREAK_POINT,
} from "../../utilities/Constants";
import { MAIN_PAGE_SUMMARY_BAR } from "../../utilities/constantsLocale";

const SummaryBarColumn = ({
  windowSize,
  headerContent,
  headerIcon,
  bodyContent,
}) => {
  return (
    <>
      {headerIcon}
      {windowSize.width > MEDIUM_WINDOW_BREAK_POINT && <h2>{headerContent}</h2>}
      <p
        style={{
          marginLeft: "5px",
          marginRight: "5px",
          paddingLeft: "5px",
          paddingRight: "5px",
        }}
      >
        {bodyContent}
      </p>
    </>
  );
};

export const MainPageSummaryBar = ({ windowSize }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          borderRadius: "5px",
          m: 2,
          backgroundColor: PRIMARY_COLOR,
          color: "white",
          fontSize: "15px",
        }}
      >
        <Grid container direction="row">
          <Grid item xs>
            <SummaryBarColumn
              windowSize={windowSize}
              headerContent={t(`${MAIN_PAGE_SUMMARY_BAR}summary-page-header-1`)}
              headerIcon={
                <ImportContacts
                  fontSize="large"
                  style={{ marginRight: "5px", marginTop: "10px" }}
                />
              }
              bodyContent={t(`${MAIN_PAGE_SUMMARY_BAR}summary-page-content-1`)}
            />
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            style={{ backgroundColor: "white" }}
            sx={{ my: "15px" }}
          />

          <Grid item xs>
            <SummaryBarColumn
              windowSize={windowSize}
              headerContent={t(`${MAIN_PAGE_SUMMARY_BAR}summary-page-header-2`)}
              headerIcon={
                <ExploreIcon
                  fontSize="large"
                  style={{ marginRight: "5px", marginTop: "10px" }}
                />
              }
              bodyContent={t(`${MAIN_PAGE_SUMMARY_BAR}summary-page-content-2`)}
            />
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            style={{ backgroundColor: "white" }}
            sx={{ my: "15px" }}
          />

          <Grid item xs>
            <SummaryBarColumn
              windowSize={windowSize}
              headerContent={t(`${MAIN_PAGE_SUMMARY_BAR}summary-page-header-3`)}
              headerIcon={
                <SwapHoriz
                  fontSize="large"
                  style={{ marginRight: "5px", marginTop: "10px" }}
                />
              }
              bodyContent={t(`${MAIN_PAGE_SUMMARY_BAR}summary-page-content-3`)}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
