import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import BookCardDialog from "./BookCardDialog";
import { Img } from "../utilities/Constants";
import { LOCALE_DEFAULT_OPTION } from "../utilities/Constants";

function BookCard(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container>
        <Paper
          sx={{
            margin: "auto",
            maxWidth: 250,
            flexGrow: 1,
            borderRadius: "0%",
            p: "0px",
          }}
          elevation={12}
        >
          <Grid item alignItems="center" justifyContent="center">
            <ButtonBase onClick={handleClickOpen}>
              <Img alt={props.bookMetadata.title} src={props.bookCover} />
            </ButtonBase>
          </Grid>
        </Paper>
        <Grid
          item
          container
          sx={{ my: "5px" }}
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            fontWeight="bold"
            gutterBottom
            variant="subtitle1"
            component="div"
            fontSize="20px"
          >
            {props.bookMetadata.title}
          </Typography>
        </Grid>
      </Grid>
      {open && (
        <BookCardDialog
          open={open}
          onClose={handleClose}
          bookMetadata={props.bookMetadata}
          bookCover={props.bookCover}
          selectedLocaleToDisplay={LOCALE_DEFAULT_OPTION}
        />
      )}
    </>
  );
}

export default BookCard;
