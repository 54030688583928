import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PRIMARY_COLOR } from "../../utilities/Constants";
import { MAIN_PAGE_FEATURED_MUSICIAN } from "../../utilities/constantsLocale";
import { useFetchData } from "../../utilities/useFetchData";
import { FeaturedArtistsBio } from "./MainPageFeaturedArtists";

const YouTubeEmbed = ({ embedId }) => (
  <div
    style={{
      overflow: "hidden",
      paddingBottom: "56.25%",
      position: "relative",
      height: 0,
    }}
  >
    <iframe
      style={{
        left: 0,
        top: 0,
        height: "100%",
        width: "100%",
        position: "absolute",
      }}
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

export const MainPageFeaturedMusician = ({ currentLanguage }) => {
  const [featuredMusic, setFeaturedBooks] = useState("6kJjbfXx54I");
  const artistData = useFetchData("artists", "1177", currentLanguage);

  const { t } = useTranslation();

  return (
    artistData && (
      <>
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <Box
            sx={{
              p: 2,
              m: 2,
            }}
          >
            <FeaturedArtistsBio bioData={artistData.metadata} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <Box
            sx={{
              borderRadius: "5px",
              p: 2,
              m: 2,
              backgroundColor: PRIMARY_COLOR,
              color: "#dfdbce",
            }}
          >
            <div align="center">
              <h1>
                {t(`${MAIN_PAGE_FEATURED_MUSICIAN}featured-musician-header`)}
              </h1>
              {t(`${MAIN_PAGE_FEATURED_MUSICIAN}featured-musician-content`)}
              <p></p>
            </div>

            <YouTubeEmbed embedId={featuredMusic} />
          </Box>
        </Grid>
      </>
    )
  );
};
