import React, { useState, useRef, useCallback } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { emphasize, styled } from "@mui/material/styles";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Grid,
} from "@mui/material";
import { useWindowDimensions } from "../utilities/useWindowDimensions";
import {
  MEDIUM_WINDOW_BREAK_POINT,
  PRIMARY_COLOR,
  LOCALE_OPTIONS,
  LOCALE_OPTIONS_TYPES,
} from "../utilities/Constants";

const StyledButton = styled(Button)(({ theme }) => {
  const backgroundColor = PRIMARY_COLOR;

  return {
    backgroundColor,
    color: "white",

    "&:hover": {
      backgroundColor: emphasize(backgroundColor, 0.2),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.5),
    },
  };
});

const LargeWindowButtonComponents = ({ onButtonClick }) => {
  return (
    <ButtonGroup
      size="large"
      variant="contained"
      aria-label=" text button group"
    >
      {LOCALE_OPTIONS.map((option, index) => (
        <StyledButton
          key={index}
          style={{ borderColor: "#fff" }}
          //disabled={index === 2 || index === 3}
          onClick={() => {
            onButtonClick(option);
          }}
        >
          {LOCALE_OPTIONS_TYPES[option]}
        </StyledButton>
      ))}
    </ButtonGroup>
  );
};

const SmallWindowButtonComponents = ({ onButtonClick }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleClick = () => {
    onButtonClick(LOCALE_OPTIONS[selectedIndex]);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    onButtonClick(LOCALE_OPTIONS[index]);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <StyledButton onClick={handleClick} style={{ borderColor: "#fff" }}>
          {LOCALE_OPTIONS_TYPES[LOCALE_OPTIONS[selectedIndex]]}
        </StyledButton>
        <StyledButton
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="Select language"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </StyledButton>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {LOCALE_OPTIONS.map((option, index) => (
                    <MenuItem
                      key={option}
                      //disabled={index === 2 || index === 3}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {LOCALE_OPTIONS_TYPES[option]}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const ReaderLanguageChanger = ({ handleLanguageSelection }) => {
  const windowSize = useWindowDimensions();

  const handleInputSelectionOnClick = useCallback(
    (selectedLanguage) => {
      selectedLanguage && handleLanguageSelection(selectedLanguage);
    },
    [handleLanguageSelection]
  );

  return (
    <Grid item xs={12} justifyContent="center" style={{ textAlign: "center" }}>
      {windowSize.width > MEDIUM_WINDOW_BREAK_POINT ? (
        <LargeWindowButtonComponents
          onButtonClick={handleInputSelectionOnClick}
        />
      ) : (
        <SmallWindowButtonComponents
          onButtonClick={handleInputSelectionOnClick}
        />
      )}
    </Grid>
  );
};

export default ReaderLanguageChanger;
