import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import MainImage from "../background.jpg";
import BookCard from "./BookCard";

import book4Content from "../data/content/books/9873_cyKk.json";
import book3Content from "../data/content/books/9874_cyKk.json";
import book2Content from "../data/content/books/9875_cyKk.json";
import book1Content from "../data/content/books/9876_cyKk.json";

const BookLibrary = () => {
  const [bookList, setBookList] = useState([]);

  useEffect(() => {
    setBookList(() => [...bookImagesAndMetadata]);
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }} style={{ backgroundImage: `url(${MainImage})` }}>
      <Grid
        container
        spacing={{ xs: 2, sm: 3, md: 4 }}
        style={{ padding: "20px" }}
      >
        {bookList.map((item, index) => (
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            key={index}
            style={{ textAlign: "center" }}
          >
            <BookCard bookCover={item.img} bookMetadata={item.metadata} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const bookImagesAndMetadata = [
  {
    img: require("../data/cover_photos/" + book1Content.metadata.uid + ".png"),
    author: book1Content.metadata.author,
    metadata: book1Content.metadata,
  },
  {
    img: require("../data/cover_photos/" + book2Content.metadata.uid + ".png"),
    author: book2Content.metadata.author,
    metadata: book2Content.metadata,
  },
  {
    img: require("../data/cover_photos/" + book3Content.metadata.uid + ".png"),
    author: book3Content.metadata.author,
    metadata: book3Content.metadata,
  },
  {
    img: require("../data/cover_photos/" + book4Content.metadata.uid + ".png"),
    author: book4Content.metadata.author,
    metadata: book4Content.metadata,
  },
];

export default BookLibrary;
export { bookImagesAndMetadata };
