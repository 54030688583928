import { styled } from "@mui/material/styles";

const CustomDiv = styled("div")(({ theme }) => ({
  "&.linear-down": {
    background:
      "linear-gradient(180deg, hsla(131, 22%, 29%, 1) 0%, hsla(102, 9%, 57%, 1) 50%, hsla(46, 21%, 84%, 1) 100%)",
    filter:
      "progid: DXImageTransform.Microsoft.gradient( startColorstr='#3A5A40', endColorstr='#8D9B87', GradientType=1 )",
  },
  "&.linear-up": {
    background:
      "linear-gradient(0deg, hsla(131, 22%, 29%, 1) 0%, hsla(102, 9%, 57%, 1) 50%, hsla(46, 21%, 84%, 1) 100%)",
    filter:
      "progid: DXImageTransform.Microsoft.gradient( startColorstr='#3A5A40', endColorstr='#8D9B87', GradientType=1 )",
  },
  "&.radial": {
    background: `radial-gradient(circle, hsla(131, 22%, 29%, 1) 0%, hsla(102, 9%, 57%, 1) 48%, hsla(46, 21%, 84%, 1) 100%)`,
    backgroundImage: `-moz-radial-gradient(circle, hsla(131, 22%, 29%, 1) 0%, hsla(102, 9%, 57%, 1) 48%, hsla(46, 21%, 84%, 1) 100%)`,
    backgroundImage: `-webkit-radial-gradient(circle, hsla(131, 22%, 29%, 1) 0%, hsla(102, 9%, 57%, 1) 48%, hsla(46, 21%, 84%, 1) 100%)`,
    filter: `progid:DXImageTransform.Microsoft.gradient(startColorstr="#3a5a40", endColorstr="#8D9B87", GradientType=1)`,
  },
  "&.temp": {
    backgroundImage: "linear-gradient(to top, #9890e3 0%, #b1f4cf 100%)",
  },
}));

export { CustomDiv };
