import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import { Button, Grid, TextField } from "@mui/material";
import React, { useCallback } from "react";
import {
  MEDIUM_WINDOW_BREAK_POINT,
  PRIMARY_COLOR,
} from "../utilities/Constants";
import { CONVERT_PAGE } from "../utilities/constantsLocale";
import { useWindowDimensions } from "../utilities/useWindowDimensions";
import LetterChanger from "./LetterChanger";

const handleOnClickCopyToClipboard = (
  currentData,
  handleInputToChangeProvider,
  handleButtonAlert,
  handleInputToChangeConsumer
) => {
  let handleStatus = "successCopied";
  try {
    navigator.clipboard.writeText(currentData);
  } catch {
    handleStatus = "errorCopied";
  }
  handleButtonAlert(handleStatus);

  return { handleStatus };
};

const handleOnClickCopyToDelete = (
  currentData,
  handleInputToChangeProvider,
  handleButtonAlert,
  handleInputToChangeConsumer
) => {
  let handleStatus = "success";
  try {
    handleInputToChangeProvider && handleInputToChangeProvider("");
    handleInputToChangeConsumer && handleInputToChangeConsumer("");
  } catch {
    handleStatus = "error";
  }

  return { handleStatus };
};

const handleOnClickDisplayCharacter = (
  currentData,
  handleInputToChangeProvider,
  handleButtonAlert,
  handleInputToChangeConsumer
) => {
  let handleStatus = "success";
  try {
  } catch {
    handleStatus = "error";
  }

  return { handleStatus };
};

const actions = [
  {
    icon: <DeleteForeverIcon />,
    name: `${CONVERT_PAGE}buttonBar.delete`,
    actionClick: handleOnClickCopyToDelete,
    buttonEnabled: true,
  },
  {
    icon: <ContentCopyIcon />,
    name: `${CONVERT_PAGE}buttonBar.copy`,
    actionClick: handleOnClickCopyToClipboard,
    buttonEnabled: true,
  },
  {
    icon: <FormatSizeIcon />,
    name: "Қазақ әріптері",
    actionClick: handleOnClickDisplayCharacter,
    buttonEnabled: false,
  },
];

const ConverterTextFieldAndButtons = ({
  labelName,
  localeName,
  handleInputToChangeProvider,
  handleInputToChangeConsumer,
  handleButtonAlert,
  contentText,
  exceededCharacterCount,
  t,
}) => {
  const windowSize = useWindowDimensions();

  const handleInputChange = useCallback(
    (event) => {
      const updatedContentText = LetterChanger(localeName, event.target.value);

      handleInputToChangeProvider(event.target.value);

      handleInputToChangeConsumer(updatedContentText);
    },
    [handleInputToChangeConsumer]
  );

  return (
    <>
      <Grid
        item
        xs={10}
        sm={10}
        md={4}
        lg={4}
        xl={4}
        alignItems="center"
        sx={{ px: 2 }}
      >
        <TextField
          fullWidth
          // id="filled-hidden-label-normal"
          error={exceededCharacterCount}
          helperText={
            exceededCharacterCount &&
            t(`${CONVERT_PAGE}alert.exceededCharacterCount`)
          }
          variant="filled"
          InputProps={{
            disableUnderline: true,
          }}
          color="success"
          sx={{ borderRadius: "5px" }}
          label={labelName}
          multiline
          rows={4}
          disabled={exceededCharacterCount}
          value={contentText}
          onInput={handleInputChange}
        />
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2} xl={2} alignItems="center">
        <Grid
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          {actions.map(
            (action) =>
              action.buttonEnabled && (
                <Button
                  key={action.name}
                  startIcon={action.icon}
                  sx={{ textAlign: "left", color: PRIMARY_COLOR }}
                  onClick={() => {
                    action.actionClick(
                      contentText,
                      handleInputToChangeProvider,
                      handleButtonAlert
                    );
                  }}
                >
                  {windowSize.width > MEDIUM_WINDOW_BREAK_POINT &&
                    t(action.name)}
                </Button>
              )
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ConverterTextFieldAndButtons;
