import React, { useState, useEffect } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
} from "@mui/material";
import {
  LOCALE_DEFAULT_OPTION,
  SMALL_WINDOW_BREAK_POINT,
} from "../utilities/Constants";
import { useWindowDimensions } from "../utilities/useWindowDimensions";
import { useFetchData } from "../utilities/useFetchData";
import { useNavigate } from "react-router-dom";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import "./styles.css";
import BookCardDialog from "../home/BookCardDialog";

function Arrow(props) {
  const disabeld = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabeld}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  );
}

const BookCard = ({ bookUid, selectedLocaleToDisplay }) => {
  const [open, setOpen] = useState(false);

  const data = useFetchData("books", bookUid, selectedLocaleToDisplay);
  const windowSize = useWindowDimensions();

  const handleClose = () => {
    setOpen(false);
  };

  const onClick = (value) => {
    setOpen(true);
  };

  return (
    data && (
      <>
        {" "}
        <Card
          sx={{ boxShadow: "none" }}
          style={{ background: "transparent" }}
          onClick={() => onClick(bookUid)}
        >
          <CardMedia
            component="img"
            // height="140"
            image={require("../data/cover_photos/" + bookUid + ".png")}
            alt=""
            style={
              windowSize.width > SMALL_WINDOW_BREAK_POINT
                ? { maxWidth: "200px" }
                : { maxWidth: "150px" }
            }
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {data.metadata.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {data.metadata.publish_date} - {data.metadata.publisher}
            </Typography>
          </CardContent>
        </Card>
        {open && (
          <BookCardDialog
            open={open}
            onClose={handleClose}
            bookMetadata={data.metadata}
            bookCover={require("../data/cover_photos/" + bookUid + ".png")}
            selectedLocaleToDisplay={selectedLocaleToDisplay}
          />
        )}
      </>
    )
  );
};

const generateBookCards = (bookCatalog, selectedLocaleToDisplay) => {
  const generatedBookCards = bookCatalog.map((uid, index) => (
    <div className={`keen-slider__slide number-slide${index}`}>
      <BookCard
        bookUid={uid}
        selectedLocaleToDisplay={selectedLocaleToDisplay}
      />
    </div>
  ));

  return generatedBookCards;
};

export const BookCards = ({ bookCatalog }) => {
  const [selectedLocaleToDisplay, setSelectedLocaleToDisplay] = useState(
    LOCALE_DEFAULT_OPTION
  );
  const [generatedBookCards, setGeneratedBookCards] = useState();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  useEffect(() => {
    setGeneratedBookCards(
      generateBookCards(bookCatalog, selectedLocaleToDisplay)
    );
  }, [bookCatalog]);

  return (
    generatedBookCards && (
      <>
        <div className="navigation-wrapper">
          {
            <div ref={sliderRef} className="keen-slider">
              {generatedBookCards}
            </div>
          }
          {loaded && instanceRef.current && (
            <>
              <Arrow
                left
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
                disabled={currentSlide === 0}
              />

              <Arrow
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
                disabled={
                  currentSlide ===
                  instanceRef.current.track.details.slides.length - 1
                }
              />
            </>
          )}
        </div>
        {loaded && instanceRef.current && (
          <div className="dots">
            {[
              ...Array(instanceRef.current.track.details.slides.length).keys(),
            ].map((idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx);
                  }}
                  className={"dot" + (currentSlide === idx ? " active" : "")}
                ></button>
              );
            })}
          </div>
        )}
      </>
    )
  );
};
