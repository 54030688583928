import React, { useState, useEffect } from "react";
import { Grid, Divider } from "@mui/material";
import { pageTitle } from "../components/PageInformation";
import {
  CONVERTER_MAX_CHAR_COUNT,
  LOCALE_DEFAULT_OPTION,
  LOCALE_LATIN_QAZAQ,
  PRIMARY_COLOR,
} from "../utilities/Constants";
import AlertMessage from "../components/AlertMessage";
import ConverterTextFieldAndButtons from "./ConverterTextFieldAndButtons";
import { CONVERT_PAGE } from "../utilities/constantsLocale";
import { useTranslation } from "react-i18next";

const ConverterPageHeader = ({ t }) => {
  return (
    <>
      <Grid item xs={12}>
        {pageTitle(t(`${CONVERT_PAGE}title`))}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {t(`${CONVERT_PAGE}descriptionTop`)}
        <Divider
          variant="middle"
          sx={{
            borderBottomWidth: 1,
            backgroundColor: PRIMARY_COLOR,
            boxShadow: "0 0 10px",
          }}
        />
        {t(`${CONVERT_PAGE}descriptionBottom`)}
      </Grid>
    </>
  );
};

const Convert = () => {
  const [contentTextCyrillic, setContentTextCyrillic] = useState("");
  const [contentTextLatin, setContentTextLatin] = useState("");
  const [exceededCharacterCount, setExceededCharacterCount] = useState(false);
  const [alertStatus, setAlertStatus] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const toDisableTextEditors =
      contentTextCyrillic.length > CONVERTER_MAX_CHAR_COUNT ||
      contentTextLatin.length > CONVERTER_MAX_CHAR_COUNT
        ? true
        : false;
    setExceededCharacterCount(toDisableTextEditors);
    setAlertStatus(toDisableTextEditors && "errorCharacterLimit");
  }, [contentTextCyrillic.length, contentTextLatin.length]);

  return (
    <Grid
      container
      justifyContent="center"
      rowSpacing={4}
      style={{ textAlign: "center", padding: "10px" }}
    >
      {<ConverterPageHeader t={t} />}
      {
        <Grid item xs={12}>
          {alertStatus && (
            <AlertMessage
              alertName={alertStatus}
              alertDelay={!exceededCharacterCount && 4000}
              t={t}
            />
          )}
        </Grid>
      }
      {
        <ConverterTextFieldAndButtons
          labelName={t(`${CONVERT_PAGE}textArea.placeHolderTop`)}
          localeName={LOCALE_LATIN_QAZAQ}
          handleInputToChangeProvider={setContentTextCyrillic}
          handleInputToChangeConsumer={setContentTextLatin}
          handleButtonAlert={setAlertStatus}
          contentText={contentTextCyrillic}
          exceededCharacterCount={exceededCharacterCount}
          t={t}
        />
      }
      {
        <ConverterTextFieldAndButtons
          labelName={t(`${CONVERT_PAGE}textArea.placeHolderBottom`)}
          localeName={LOCALE_DEFAULT_OPTION}
          handleInputToChangeProvider={setContentTextLatin}
          handleInputToChangeConsumer={setContentTextCyrillic}
          handleButtonAlert={setAlertStatus}
          contentText={contentTextLatin}
          exceededCharacterCount={exceededCharacterCount}
          t={t}
        />
      }
    </Grid>
  );
};

export default Convert;
