import { default as MainIcon } from "@mui/icons-material/ImportContacts";
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MainImage from "../background.jpg";
import LetterChanger from "../converter/LetterChanger";
import { Img, PRIMARY_COLOR } from "../utilities/Constants";
import { MAIN_PAGE_BOOK_CARD_DIALOG } from "../utilities/constantsLocale";

const ReadMore = ({ title, content }) => {
  // const [isReadMore, setIsReadMore] = useState(true);
  // const toggleReadMore = () => {
  //   setIsReadMore(!isReadMore);
  // };

  const text = content;

  return (
    <div
      className="textBiography"
      style={{
        height: "300px",
        overflow: "auto",
        whiteSpace: "break-spaces",
      }}
    >
      {title}
      {/* {isReadMore ? text.slice(0, 150) : text} */}
      {text}
      {/* <Typography
        sx={{ textDecoration: "underline", color: "blue" }}
        onClick={toggleReadMore}
      >
        {isReadMore ? "...Көбірек көрсет" : " ...Азырақ көрсету"}
      </Typography> */}
    </div>
  );
};

const BookCardDialog = ({
  open,
  onClose,
  bookMetadata,
  bookCover,
  selectedLocaleToDisplay,
}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  const onClick = (uid) => {
    //<Link to={`reader/${uid}`}></Link>;
    navigate(`/reader/${uid}`);
    //navigate("/reader", { state: { uid: uid } });
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Grid
        container
        direction="row"
        style={{ padding: "10px", backgroundImage: `url(${MainImage})` }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item md={6} lg={6} key="image-card-dialog">
          <DialogTitle sx={{ textAlign: "center" }}>
            {LetterChanger(selectedLocaleToDisplay, bookMetadata.title)}
          </DialogTitle>

          <Img
            sx={{ maxWidth: "200px", textAlign: "center" }}
            alt={bookMetadata.title}
            src={bookCover}
          />
        </Grid>
        <Grid item md={6} lg={6} key="image-card-content">
          <List>
            <ListItem>
              <span style={{ fontWeight: "bold" }}>
                {" "}
                {t(`${MAIN_PAGE_BOOK_CARD_DIALOG}author`)}:&nbsp;
              </span>{" "}
              {LetterChanger(selectedLocaleToDisplay, bookMetadata.author)}
            </ListItem>
            <ListItem>
              <span style={{ fontWeight: "bold" }}>
                {t(`${MAIN_PAGE_BOOK_CARD_DIALOG}publisher`)}:&nbsp;
              </span>{" "}
              {LetterChanger(selectedLocaleToDisplay, bookMetadata.publisher)}
            </ListItem>
            <ListItem>
              <span style={{ fontWeight: "bold" }}>
                {t(`${MAIN_PAGE_BOOK_CARD_DIALOG}publicationDate`)}:&nbsp;
              </span>{" "}
              {LetterChanger(
                selectedLocaleToDisplay,
                bookMetadata.publish_date
              )}
            </ListItem>
            <ListItem>
              {
                <ReadMore
                  title={
                    <span style={{ fontWeight: "bold" }}>
                      {t(`${MAIN_PAGE_BOOK_CARD_DIALOG}summary`)}:&nbsp;
                    </span>
                  }
                  content={LetterChanger(
                    selectedLocaleToDisplay,
                    bookMetadata.summary
                  )}
                />
              }
            </ListItem>
          </List>
          <div style={{ textAlign: "center" }}>
            {" "}
            <Button
              variant="contained"
              onClick={() => onClick(bookMetadata.uid)}
              endIcon={<MainIcon sx={{ mb: "5px" }} />}
              style={{ backgroundColor: PRIMARY_COLOR }}
            >
              {t(`${MAIN_PAGE_BOOK_CARD_DIALOG}openBookButton`)}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default BookCardDialog;
