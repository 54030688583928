import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { PRIMARY_COLOR } from "../../utilities/Constants";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MAIN_PAGE_FEATURED_FACTS } from "../../utilities/constantsLocale";

const MainPageHistoryFact = ({ header, body }) => {
  return (
    <Accordion sx={{ backgroundColor: PRIMARY_COLOR, color: "white" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{header}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{body}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export const MainPageFeaturedFacts = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <h1>{t(`${MAIN_PAGE_FEATURED_FACTS}header`)}</h1>
      <MainPageHistoryFact
        index={1}
        header={"Қазақ әліпбиі және оның негізін салушылар"}
        body={
          "Алғашқы қазақ кирил әліпбиін 19-шы ғасырда Ыбырай Алтынсарин жасап шығарған. Ал Ахмет Байтұрсынұлы Араб жазуын негізін салушылардың бірі."
        }
      />

      <MainPageHistoryFact
        index={2}
        header={"Тұңғыш қазақ пьесасы және тұңғыш театр"}
        body={
          "1926 жылы 13 қаңтарда Қызылордада тұңғыш қазақ кәсіби театры ашылып, Мұхтар Әуезовтің «Еңлік — Кебек» пьесасы орындалды"
        }
      />
      <MainPageHistoryFact
        index={3}
        header={"Қазақ әдебиетінің ренессансы"}
        body={
          "Қазақ әдебиетiнiң өскелең бағытын айқындауда 19 ғасырдың екiншi жартысында туған демократиялық ағартушылық әдебиеттiң орны ерекше. Бұл әдебиеттiң өкiлдерi Ыбырай Алтынсарин, Абай Құнанбаев, Шоқан Уәлиханов ғылым-бiлiм, оқу-өнер арқылы алдыңғы қатарлы елдерге теңелуге, теңдiкке жетуге болатынына сендi"
        }
      />
    </>
  );
};
