import React from "react";
import { useNavigate } from "react-router-dom";
import pageContent, {
  BackButton,
  pageTitle,
} from "../components/PageInformation";

import WIPIcon from "../data/images/wip.svg";

import { SVGStyledComponent } from "../utilities/Constants";

import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";
import { WORK_IN_PROGRESS_PAGE } from "../utilities/constantsLocale";

const WorkInProgress = (props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Grid container style={{ padding: "15px" }} spacing={2}>
      {pageTitle(t(`${WORK_IN_PROGRESS_PAGE}title`))}

      <Grid item xs={12} sm={12} md={6} lg={6} textAlign="center">
        {pageContent(t(`${WORK_IN_PROGRESS_PAGE}content`))}
        <BackButton
          navigate={navigate}
          link={props?.link}
          linkText={t(`${WORK_IN_PROGRESS_PAGE}libraryButton`)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        justifyContent="center"
        style={{ textAlign: "center", padding: "10px" }}
      >
        {SVGStyledComponent(WIPIcon, "Work in progress")}
      </Grid>
    </Grid>
  );
};

export default WorkInProgress;
