import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { LOCALE_CYRILLIC_QAZAQ, LOCALE_LATIN_QAZAQ } from "./Constants";

export function useFetchData(type, uID, localeID) {
  const [content, setContent] = useState(null);

  const fetchData = useCallback(async () => {
    let contentURL = "";
    if (type === "artwork") {
      contentURL = `data/content/${type}/${uID}`;
    } else if (!localeID) {
      contentURL = `data/content/${type}/${uID}_${LOCALE_CYRILLIC_QAZAQ}.json`;
    } else {
      contentURL = `data/content/${type}/${uID}_${localeID}.json`;
    }

    console.log(`Fetching data from ${contentURL}`);

    const data = await import(`../${contentURL}`)
      .then((module) => module.default)
      .catch(() => {
        return null;
      });

    setContent(data);
  }, [uID, localeID]);

  useEffect(() => {
    try {
      fetchData();
    } catch {
      return null;
    }
  }, [fetchData]);

  return content;
}
