import SendIcon from "@mui/icons-material/Send";
import { Button, Divider, Grid, TextField } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AlertMessage from "../components/AlertMessage";
import pageContent, {
  BackButton,
  pageTitle,
} from "../components/PageInformation";
import PrimaryShareDialog, {
  FallbackShareDialog,
} from "../components/ShareDialog";
import ContactIcon from "../data/images/contact_us.svg";
import ShareIcon from "../data/images/share_us.svg";
import { PRIMARY_COLOR, SVGStyledComponent } from "../utilities/Constants";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const ContactForm = ({ navigate }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [openShareDialogStatus, setShareDialogStatus] = React.useState(false);
  const [alertName, setAlertName] = React.useState(null);

  const handleCloseDialog = () => {
    setShareDialogStatus(false);
  };

  const onSubmit = (data) => {
    //console.log(data);

    //Netlify form submission: https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/?_ga=2.154655676.569415638.1657996386-1169451462.1657248038
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact-form", ...data }),
    })
      .then(() => setAlertName("successMessageSent"))
      .catch(() => setAlertName("errorMessageSent"));

    reset();

    const isPrimaryShareDialogSupported = PrimaryShareDialog(
      "title",
      "chapter"
    );
    setShareDialogStatus(isPrimaryShareDialogSupported);
  };

  return (
    <>
      {" "}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container style={{ padding: "15px" }} spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            {" "}
            <TextField
              name="name"
              error={errors.name?.message}
              helperText={errors.name?.message}
              fullWidth
              id="outlined-basic"
              label="Сіздің атыңыз"
              variant="outlined"
              {...register("name", { required: "Сіздің атыңыз" })}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            {" "}
            <TextField
              name="email"
              error={errors.email?.message}
              helperText={errors.email?.message}
              fullWidth
              id="outlined-basic"
              label="Электрондық пошта"
              variant="outlined"
              {...register("email", { required: "Электрондық пошта қажет" })}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            {" "}
            <TextField
              name="messageBody"
              error={errors.messageBody?.message}
              multiline
              maxRows={6}
              fullWidth
              id="outlined-basic"
              label="Сіздің пікіріңіз"
              variant="outlined"
              {...register("messageBody", {
                required: "Ақпарат жеткіліксіз. Тағы біраз қосыңыз",
                minLength: 5,
              })}
            />
            <p style={{ color: "red" }}>{errors.messageBody?.message}</p>
          </Grid>
          <Grid item xs={6} sm={6} textAlign="center">
            <Button
              type="submit"
              variant="contained"
              style={{ backgroundColor: PRIMARY_COLOR }}
              startIcon={<SendIcon style={{ marginBottom: "4px" }} />}
            >
              Жіберу
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} textAlign="center">
            <BackButton navigate={navigate} />
          </Grid>
        </Grid>
      </form>
      {openShareDialogStatus && alertName && (
        <FallbackShareDialog
          onClose={handleCloseDialog}
          open={openShareDialogStatus}
          imagePreview={SVGStyledComponent(ShareIcon, "Share")}
          dialogMessage={
            <AlertMessage alertName={alertName} alertDelay={10000} />
          }
        />
      )}
    </>
  );
};

const Contact = () => {
  const navigate = useNavigate();

  return (
    <Grid container style={{ padding: "15px" }} spacing={2}>
      {pageTitle("Бізбен хабарласыңыз")}
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        justifyContent="center"
        style={{ textAlign: "center", padding: "10px" }}
      >
        {SVGStyledComponent(ContactIcon, "Contact")}
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <>
          {pageContent(
            "The goal of this page is to preserve linguistic and cultural heritage of Kazakhstan. It is dedicated to individuals whose contributions enriched Kazakh culture.",
            "enUs"
          )}

          {
            <Divider
              variant="middle"
              sx={{
                borderBottomWidth: 1,
                backgroundColor: PRIMARY_COLOR,
                boxShadow: "0 0 10px",
                my: 2,
              }}
            />
          }
          {pageContent(
            "Бұл парақшаның мақсаты – Қазақстанның тілдік және мәдени мұрасын сақтау. Қазақ мәдениетін байытқан тұлғаларға арналған.",
            "qaz-Cy"
          )}
          {<ContactForm navigate={navigate} />}
        </>
      </Grid>
    </Grid>
  );
};

export default Contact;
