import { styled } from "@mui/material/styles";

export const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
  boxShadow: "5px 5px 10px",
});

const ImageStyledComponent = (
  imageSource,
  imageAlternativeText = "altText"
) => {
  return (
    <Img
      alt={imageAlternativeText}
      src={imageSource}
      sx={{ maxWidth: 150, m: "20px" }}
    />
  );
};

export const SVGStyledComponent = (
  svgSource,
  svgAlternativeText = "altText"
) => {
  return (
    <img
      alt={svgAlternativeText}
      src={svgSource}
      style={{ width: "80%", height: "auto", viewBox: "0 0 150 150" }}
    />
  );
};

const WEBSITE_URL = "https://qazBilim.com";
const WEBSITE_NAME = "qazBilim.com";

const LARGE_WINDOW_BREAK_POINT = 900;
const MEDIUM_WINDOW_BREAK_POINT = 600;
const SMALL_WINDOW_BREAK_POINT = 300;

const PRIMARY_COLOR = "#3a5a40";

const PAGINATION_PER_PAGE = 1;

const CONVERTER_MAX_CHAR_COUNT = 500;

const LOCALE_CYRILLIC_QAZAQ = "cyKk";
const LOCALE_LATIN_QAZAQ = "laKk";
const LOCALE_ENGLISH_US = "enUs";
const LOCALE_RUSSIAN = "ru";

const LOCALE_DEFAULT_OPTION = LOCALE_CYRILLIC_QAZAQ;

const LOCALE_OPTIONS = [
  LOCALE_LATIN_QAZAQ,
  LOCALE_DEFAULT_OPTION,
  LOCALE_ENGLISH_US,
  LOCALE_RUSSIAN,
];

const LOCALE_OPTIONS_TYPES = {
  [LOCALE_LATIN_QAZAQ]: "Qazaqşa-Latinşa",
  [LOCALE_DEFAULT_OPTION]: "Қазақша-Кириллицада",
  [LOCALE_ENGLISH_US]: "English",
  [LOCALE_RUSSIAN]: "Русский",
};

export {
  CONVERTER_MAX_CHAR_COUNT,
  ImageStyledComponent,
  LARGE_WINDOW_BREAK_POINT,
  LOCALE_CYRILLIC_QAZAQ,
  LOCALE_DEFAULT_OPTION,
  LOCALE_ENGLISH_US,
  LOCALE_LATIN_QAZAQ,
  LOCALE_OPTIONS,
  LOCALE_OPTIONS_TYPES,
  LOCALE_RUSSIAN,
  MEDIUM_WINDOW_BREAK_POINT,
  PAGINATION_PER_PAGE,
  PRIMARY_COLOR,
  SMALL_WINDOW_BREAK_POINT,
  WEBSITE_NAME,
  WEBSITE_URL,
};
