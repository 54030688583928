import React, { useState } from "react";
import { emphasize, styled } from "@mui/material/styles";
import { Pagination, PaginationItem, Grid } from "@mui/material";
import { PRIMARY_COLOR } from "../utilities/Constants";
import pageContent, { pageTitle } from "../components/PageInformation";
import LetterChanger from "../converter/LetterChanger";

const StyledPaginationItem = styled(PaginationItem)(({ theme }) => {
  const backgroundColor = PRIMARY_COLOR;

  return {
    backgroundColor,
    borderRadius: "4px",
    color: "white",
    fontWeight: theme.typography.fontWeightRegular,

    "&:hover": {
      backgroundColor: emphasize(backgroundColor, 0.2),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.5),
    },
  };
});

const ReaderPagination = ({
  paginatedData,
  count,
  selectedLocaleToDisplay,
}) => {
  let [page, setPage] = useState(1);

  const handleChange = (e, p) => {
    setPage(p);
    paginatedData.jump(p);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12} style={{ textAlign: "center" }}>
        {paginatedData.currentData().map((v) => {
          return (
            <div key={v.title}>
              {pageTitle(LetterChanger(selectedLocaleToDisplay, v.title))}
              {pageContent(LetterChanger(selectedLocaleToDisplay, v.chapter))}
            </div>
          );
        })}
      </Grid>
      <Grid item style={{ justifyContent: "center" }}>
        <Pagination
          count={count}
          size="large"
          page={page}
          shape="rounded"
          onChange={handleChange}
          renderItem={(item) => <StyledPaginationItem {...item} />}
        />
      </Grid>
    </Grid>
  );
};

export default ReaderPagination;
